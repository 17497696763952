<template>
	<Transition name="slide-fade" appear>
        <div class="w-A4W m-auto bg-white  container"> 
            <div v-if="assessment.final_level > 3" class="rounded shadow-t3 items-center relative md:mt-0">


                <div class="w-full p-8 flex justify-between">
                    <h2 class="text-xl text-t3 font-bold sm:text-3xl dark:text-white">
                        Certificate Preview
                    </h2>
                    <div class="relative px-2">
                        <label for="customRange3" class="form-label">Change Size</label>
                        <input
                            type="range"
                            class="
                            form-range
                            appearance-none
                            w-full
                            h-6
                            p-0
                            bg-transparent
                            focus:outline-none focus:ring-0 focus:shadow-none
                            "
                            min="0"
                            max="5"
                            step="1"
                            id="customRange3"
                            v-model="stepper"
                        />
                        </div>
                    <div class="flex gap-3 items-center">
                        <div>
                            <label class="form-check-label inline-block text-gray-500" for="backgrounder">
                            View Background
                            </label>
                            <input type="checkbox" id="backgrounder" v-model="viewBackground"  class="form-check-input appearance-none  h-4 w-4 border border-gray-300 rounded-xl-sm bg-white checked:bg-blue-600 checked:border-blue-600 focus:outline-none transition duration-200 mt-1 align-top bg-no-repeat bg-center bg-contain float-left mr-2 cursor-pointer"/>
                        </div>
                        <button @click="exportToPDF()" class=" w-48 m-auto font-medium bg-t3 p-3 text-white hover:text-white hover:bg-t3-secondary block" >Download</button>
                        
                    </div>
                </div>
                <div id="certificate" class="relative top-0 h-2page overflow-hidden">

                    <div class="h-A4H w-full m-auto text-center relative top-0" >
                        <img v-if="viewBackground" class="w-A4W h-A4H absolute top-0"  width="794" height="1123" src="../assets/cert-frm.svg">
                        <img class="w-A4W h-A4H absolute top-0"  width="794" height="1123" src="../assets/cert-frm2.svg">
                        <!-- <img class="w-A4W h-A4H absolute"  src="../assets/cert-bg.svg"> -->
                        <div class="flex flex-col top-24 absolute w-full text-center items-start ">
                            <div class=" font-medium text-xs text-gray-500 ml-auto mr-32 ">
                                <p class="text-left align-text-top"  >Candidate No: {{assessment.customer_id}}</p>
                                <p class="text-left align-text-top">ELP TO Certificate No: LIC-ELP-2020/07</p>
                            </div>
                            
                        </div>
                        <div class="flex flex-col absolute w-full justify-center " style="top:19rem">
                            <div class="flex gap-16 px-20">
                                <div >
                                <img  class=" h-32 w-32 object-cover object-center rounded-xl mr-6 border-t3 border-2 " :src="assessment.customer_image"/>
                            </div>
                                <div class="flex-1">
                                    <div class="flex w-full p-8 pt-2 text-center  rounded-xl justify-center ">

                                        <h2  class=" font-medium align-text-top " :class="textSize()">{{assessment.certificate_name}}</h2>
                                    </div>
                                    <p class=" text-center font-lighter text-md tracking-wider leading-5 text-sm text-gray-600 w-96 mt-4  mx-auto align-text-top"> has obtained the <span class="font-bold">{{trueLevel}}</span><br>on the English Language Proficiency Test For Pilots<br>with the following scores:</p>
                                </div>
                            </div>
                            <div v-if="assessment.min_descriptor_levels" class="flex w-full absolute  px-28 justify-between grid grid-cols-3 gap-4 "  style="top:20rem">
                                <div class=" flex items-start gap-2 justify-end">
                                    <p class=" text-sm uppercase align-text-top">Pronunciation</p>
                                    <p class=" font-lighter flex justify-center items-start rounded  border border-gray-500 h-10 w-10 align-text-top">{{assessment.min_descriptor_levels.pronunciation}}</p>
                                </div>
                                <div class=" flex items-start gap-2 justify-end">
                                    <p class=" text-sm uppercase align-text-top">Structure</p>
                                    <p class=" font-lighter flex justify-center items-start rounded  border border-gray-500 h-10 w-10 align-text-top">{{assessment.min_descriptor_levels.structure}}</p>
                                </div>
                                <div class=" flex items-start gap-2 justify-end">
                                    <p class=" text-sm uppercase align-text-top">Vocabulary</p>
                                    <p class=" font-lighter flex justify-center items-start rounded  border border-gray-500 h-10 w-10 align-text-top">{{assessment.min_descriptor_levels.vocabulary}}</p>
                                </div>
                                <div class=" flex items-start gap-2 justify-end">
                                    <p class=" text-sm uppercase align-text-top">Fluency</p>
                                    <p class=" font-lighter flex justify-center items-start rounded  border border-gray-500 h-10 w-10 align-text-top">{{assessment.min_descriptor_levels.fluency}}</p>
                                </div>
                                <div class=" flex items-start gap-2 justify-end">
                                    <p class=" text-sm uppercase align-text-top">Comprehension</p>
                                    <p class=" font-lighter flex justify-center items-start rounded  border border-gray-500 h-10 w-10 align-text-top">{{assessment.min_descriptor_levels.comprehension}}</p>
                                </div>
                                <div class=" flex items-start gap-2 justify-end">
                                    <p class=" text-sm uppercase align-text-top">Interactions</p>
                                    <p class=" font-lighter flex justify-center items-start rounded  border border-gray-500 h-10 w-10 align-text-top">{{assessment.min_descriptor_levels.interaction}}</p>
                                </div>
                                
                            </div>
                            <div class="flex w-full absolute text-3xl "  style="top:27rem">
                                <p class=" font-bold text-t3 ml-auto mt-0 mr-32 align-text-top">{{assessment.final_level}}</p>
                            </div>
                            <div class="flex w-full absolute justify-between px-24 items-center"  style="top:34rem">
                                <div class="flex text-left  flex-col">
                                    <p class=" font-lighter text-sm text-gray-500 align-text-top">Issue date:<span class="px-1.5"></span> {{todaydate}}</p>
                                    <p class=" font-lighter text-sm text-gray-500  align-text-top">Expiry date: <span class="px-1"></span>{{expirydate}}</p>
                                </div>
                                <div class="flex text-left items-center h-full flex-col">
                                    <img  class="my-auto"  width="150" height="150" src="../assets/stamp.svg">
                                </div>
                                <div class="flex text-left  flex-col items-center justify-center">
                                    <img width="141" height="48" src="../assets/kseniya-sign.svg">
                                    <p class=" font-lighter text-sm text-center text-gray-500 mt-2">Kseniya Kuzniatsova</p>
                                    <p class=" font-lighter text-sm text-center text-gray-500  ">English Language Expert</p>
                                </div>
                            </div>
                            <!-- <div class="flex w-full mt-6">
                                <div class="flex text-center text-sm flex-col mx-auto ">
                                    <p class=" font-lighter text-gray-500" v-if=" addresses.find(a => a.id === assessment.location)">{{addresses.find(a => a.id === assessment.location).address}}</p>
                                </div>
                            </div> -->
                        </div>
                        
                    </div>
                    <div class="h-A4H w-full m-auto text-center relative" >
                        <img v-if="viewBackground" class="w-A4W h-A4H absolute top-0"  src="../assets/ELPCertificate_Page2.png">
                        
                    </div>
                </div>

            

            </div>
            <div v-if="assessment.final_level <= 3" class="rounded-xl shadow-t3  justify-center items-center relative md:mt-0">
            This person has failed.
            </div>
        </div>
    </Transition>
</template>

<script>
// import { watchEffect } from 'vue'
import { mapActions, mapGetters, mapState } from 'vuex';
// import jsPDF from 'jspdf'
import html2pdf from "html2pdf.js";
//  import Swal from 'sweetalert2';
//  import QuestionsServices from '../services/QuestionsServices';

    export default {
        props:{
            id:{
                type:Number
            }
        },
        data() {
			return {
                long:false,
                viewBackground:true,
                stepper:'3',
			}
		},

        methods: {
        ...mapActions({
			getassessment: 'Assessments/getAssessment',
            getaddresses:'Settings/getAllAddresses',
			getcustomer: 'Users/getcustomer',
			getquestions: 'Questions/getAllQuestions',
			getsample: 'Samples/getSample',
			getsettings: 'Settings/getAllSettings',
            createquestion: 'Questions/createQuestion',
        }),
        textSize(){
        let size = "text-lg"
        switch(this.stepper){
            case '0':
                size = "text-xs mb-4"
                break;
            case '1':
                size = "text-sm mb-3"
                break;
            case '2':
                size = "text-base mb-2"
                break;
            case '3':
                size = "text-lg mb-1"
                break;
            case '4':
                size = "text-xl mb-1"
                break;
            case '5':
                size = "text-2xl"
                break;
            
        }
        return size
      },
        exportToPDF() {
            const element = document.getElementById('certificate');
            const opt = {
                margin:       0,
                filename:     this.assessment.customer_name+'-'+this.assessment.date+'.pdf',
                image:        { type: 'jpeg', quality: 0.95 },
                html2canvas:  { scale: 2 ,useCORS: true },
                jsPDF:        { orientation: 'portrait' }
            };
            html2pdf().set(opt).from(element).toPdf().save()
        }
         },

        computed: {
        ...mapState({
            addresses: state => state.Settings.addresses,
            customer: state => state.Users.customer,
			assessment: state => state.Assessments.assessment,
			settings: state => state.Settings.settings,
        }),
        ...mapGetters('Users', [
        'customerOnly',
        'staffOnly',
        "ELE",
        "OPE"
      ]),
      
      trueLevel(){
        let sus=""
        switch(this.assessment.final_level){
            case 6:
                sus = "Expert Level (6)"
                break;
            case 5:
                sus = "Extended Level (5)"
                break;
            case 4:
                sus = "Operational Level (4)"
                break;
        }
        return sus
      },
              assessmentCustomer() {
				return this.customer
			},
            todaydate(){
                let date = new Date(this.assessment.completion_time)
                
                return date.toLocaleDateString('en-GB',{year: 'numeric', month: 'short', day: 'numeric'})
            },
            expirydate(){
                let date = new Date(this.assessment.completion_time)
                        switch(this.assessment.final_level){
                case 6:
                    date= "Lifetime"
                    break;
                case 5:
                    date.setFullYear(date.getFullYear() + 6);
                    date.setDate(date.getDate()-1)
                    break;
                case 4:
                    date.setFullYear(date.getFullYear() + 3);
                    date.setDate(date.getDate()-1)
                    break;
                }
                if(this.assessment.final_level === 6){
                    return date
                }
                else{
                    return date.toLocaleDateString('en-GB',{year: 'numeric', month: 'short', day: 'numeric'})
                }
                
            }
		},

        created() {
			this.getassessment(this.id).then(()=>{
                if(this.assessment.certificate_name.length > 25){
                    this.long = true
                } 
            })
			this.getsettings()
			
            this.getaddresses()				
	}
    }
</script>

<style>
@page {
    margin: 0;
    padding:0;
    overflow: hidden;
    size:portrait;
    height:100%;
}
@page h1,h2,h3,h4,h5,p{

    padding-bottom:3px;

}



</style>